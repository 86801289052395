import React from 'react';
import {
  Center,
  SectionList,
  Heading,
  FlatList,
  Text,
  Pressable,
  Box,
  HStack,
  VStack,
  Button,
} from 'native-base';
import { Linking } from 'react-native';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';

const MenueListScreen = (props) => {
  const openMenuesite = (url) => {
    Linking.openURL(url);
  };

  const returnHome = async () => {
    console.log(props);
    props.navigation.goBack();
  };

  const data = props.route.params.menues;

  const getTypeIcon = (type) => {
    switch (type) {
      case 'main':
        return 'fish';
      case 'sub':
        return 'carrot';
      case 'soup':
        return 'bowl';
      default:
        return undefined;
    }

    return;
  };

  return (
    <Center h="100%" w="100%">
      <SectionList
        // maxW="300"
        w="100%"
        mb="4"
        sections={data}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => (
          <Center>
            <Box>
              <HStack space={2} alignItems="center" mt="4" pb="4">
                <MaterialCommunityIcons
                  name={getTypeIcon(item.category)}
                  size={24}
                  color="warmGray.600"
                />
                <Heading fontSize="xl">{item.category}</Heading>
              </HStack>
              <Pressable
                onPress={() => openMenuesite(item.url)}
                rounded="8"
                overflow="hidden"
                borderWidth="1"
                borderColor="warmGray.600"
                maxW="96"
                w="96"
                shadow="3"
                bg="coolGray.100"
                p="5">
                <Text mt="2" fontSize="sm" color="warmGray.600">
                  {item.title}
                </Text>
              </Pressable>
            </Box>
          </Center>
        )}
        renderSectionHeader={({ section: { title } }) => (
          <Center>
            <Heading color="rose.400" fontSize="xl" mt="8" pb="4">
              {title}
            </Heading>
          </Center>
        )}
      />
      <Button onPress={returnHome} colorScheme="rose" bg="rose.400" m="2">
        食材の登録に戻る
      </Button>
    </Center>
  );
};

export default MenueListScreen;
