/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './screens/HomeScreen';
import RegisterScreen from './screens/RegisterScreen';
import LoginScreen from './screens/LoginScreen';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { View, Text } from 'react-native';
import UnauthorizedEmailScreen from './screens/UnauthorizedEmailScreen';
import { NativeBaseProvider, Box } from 'native-base';
import MenueListScreen from './screens/MenueListScreen';
import AppBar from './components/AppBar';

const Stack = createNativeStackNavigator();
export default function App() {
  const [user, setUser] = useState();

  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState('kame.hidetoshi.02@gmail.com');
  const [password, setPassword] = useState('haihaihai1');

  useEffect(() => {
    const unsubscribe = signInWithEmailAndPassword(auth, email, password).then((result) => {
      setLoading(false);
      if (result.user) {
        if (result.user.emailVerified) {
          setUser(result.user.toString());
          // アドセンスの審査対応
          // onAuthStateChanged(auth, (user) => {
          // console.log(user);
          // setLoading(false);
          // if (user) {
          //   if (user.emailVerified) {
          //     setUser(user.toString());
        } else {
          // setUser(user.toString());
          // sendEmailVerification(user)
          //   .catch((err) => handle(err))
          //   .then(() => console.log('this will succeed'));
        }
        // TODO:ユーザーがメアド認証済みであれば、StateにUser情報をセット
        // 認証済みでなければ、認証メールを投げる（ユーザー情報はそのままStateにセットしておく）
      } else {
        setUser('');
      }
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}>
        <Text>Loading...</Text>
      </View>
    );
  } else {
    return (
      <NativeBaseProvider>
        <AppBar user={user} />
        <NavigationContainer>
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
            }}>
            {user ? (
              <>
                <Stack.Screen name="Home" component={HomeScreen} />
                <Stack.Screen name="MenueList" component={MenueListScreen} />
              </>
            ) : (
              <>
                <Stack.Screen name="Login" component={LoginScreen} />
                <Stack.Screen name="UnauthorizedEmail" component={UnauthorizedEmailScreen} />
                <Stack.Screen name="Register" component={RegisterScreen} />
              </>
            )}
          </Stack.Navigator>
        </NavigationContainer>
      </NativeBaseProvider>
    );
  }
}
