import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase';
import {
  VStack,
  HStack,
  Text,
  Box,
  Center,
  FormControl,
  Heading,
  Input,
  Link,
  Button,
} from 'native-base';

const LoginScreen = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // console.log(props);

  const handleLogin = async () => {
    try {
      // console.log(props);
      await signInWithEmailAndPassword(auth, email, password).then((result) => {
        if (!result.user.emailVerified) {
          sendEmailVerification(result.user)
            .catch((err) => console.error(err))
            .then(() => console.log('this will succeed'));
          props.navigation.navigate('UnauthorizedEmail');
        }
      });
    } catch (error: any) {
      console.log(error.message);
    }
  };
  return (
    <Center w="100%">
      <Box safeArea p="2" py="8" w="90%" maxW="290">
        <Heading size="lg" fontWeight="600" color="warmGray.600">
          Wellcom!!
        </Heading>
        <Heading mt="1" color="warmGray.600" fontWeight="medium" size="xs">
          まずメールアドレス、パスワードを入力してログインしてください
        </Heading>

        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              onChangeText={setEmail}
              value={email}
              placeholder="メールアドレスを入力してください"
              autoCapitalize="none"
              autoCorrect={false}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label>Password</FormControl.Label>
            <Input
              onChangeText={setPassword}
              value={password}
              placeholder="パスワードを入力してください"
              secureTextEntry={true}
              autoCapitalize="none"
            />
          </FormControl>

          <Button onPress={handleLogin} colorScheme="rose" bg="rose.400">
            ログイン
          </Button>

          <Text color="warmGray.600">まだアカウント登録されてない場合は、</Text>
          <Link
            onPress={() => props.navigation.navigate('Register')}
            _text={{
              color: 'rose.400',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}>
            こちらをタップ
          </Link>
        </VStack>
      </Box>
    </Center>
  );
};

export default LoginScreen;
