import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase';
import {
  VStack,
  HStack,
  Text,
  Box,
  Center,
  FormControl,
  Heading,
  Input,
  Link,
  Button,
} from 'native-base';
const RegisterScreen = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async () => {
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);
      console.log(user);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sendEmailVerification(user)
        .catch((err) => console.error(err))
        .then(() => console.log('this will succeed'));
      props.navigation.navigate('UnauthorizedEmail');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log(error.message);
    }
  };

  return (
    <Center w="100%">
      <Box safeArea p="2" py="8" w="90%" maxW="290">
        <Heading size="lg" fontWeight="600" color="warmGray.600">
          アカウント登録
        </Heading>
        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              autoCapitalize="none"
              onChangeText={setEmail}
              value={email}
              autoCorrect={false}
              placeholder="メールアドレスを入力してください"
            />
          </FormControl>
          <FormControl>
            <FormControl.Label>Password</FormControl.Label>

            <Input
              placeholder="パスワードを入力してください"
              onChangeText={setPassword}
              value={password}
              secureTextEntry={true}
              autoCapitalize="none"
            />
          </FormControl>
          <Button onPress={handleRegister} colorScheme="rose" bg="rose.400">
            登録する
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

export default RegisterScreen;
