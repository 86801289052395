import React from 'react';
import { TouchableOpacity } from 'react-native';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import AlertMessage from '../components/AlertMessage';
import axios from 'axios';
import {
  Text,
  HStack,
  Heading,
  Spinner,
  IconButton,
  Box,
  Center,
  VStack,
  Input,
  Button,
  FlatList,
  useContrastText,
} from 'native-base';
import { MaterialIcons, Ionicons } from '@expo/vector-icons';

function Item({ text, onPressDelete }) {
  return (
    <Box borderBottomWidth="1" borderColor="warmGray.600" pl={['0', '4']} pr={['0', '5']} py="2">
      <HStack alignItems="center" w="100%" justifyContent="space-between">
        <Text color="warmGray.600" bold>
          {text}
        </Text>
        <IconButton
          icon={
            <MaterialIcons name="delete" size={24} color="warmGray.600" onPress={onPressDelete} />
          }
        />
      </HStack>
    </Box>
  );
}

const HomeScreen = (props) => {
  const callSerchMenue = () => {
    setShow(false);
    setLoading(true);
    onAuthStateChanged(auth, (user) => {
      console.log(user.accessToken);
      const headers = { headers: { Authorization: 'Bearer ' + user.accessToken } };

      const url = 'https://www.recomenue.com/menues?';
      let params = '';
      const key = 'ins';
      for (const ins of ingredients) {
        params = params + key + '=' + ins.text + '&';
      }

      axios
        .get(url + params, headers)
        .catch((err) => {
          setAlertMessage(err.response.data.detail);
          setLoading(false);
          setShow(true);
        })
        .then((response) => {
          if (response) {
            console.log(response);
            props.navigation.navigate('MenueList', {
              menues: response.data,
            });
            setLoading(false);
          }
        });
    });
  };

  type Ingredients = {
    id: number;
    text: string;
  };

  const [text, onChangeText] = React.useState('');
  const [ingredients, setIngredients] = React.useState<Ingredients[]>([]);
  const [id, setID] = React.useState(1);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  if (loading) {
    return (
      <Center>
        <Box safeArea p="2" py="8" w="90%" maxW="290">
          <HStack space={2} justifyContent="center">
            <Spinner accessibilityLabel="Loading posts" color="rose.400" />
            <Heading color="rose.400" fontSize="md">
              Loading
            </Heading>
          </HStack>
        </Box>
      </Center>
    );
  } else {
    return (
      <Center w="100%">
        <Box safeArea p="2" py="8" w="90%" maxW="290">
          <AlertMessage alertMessage={alertMessage} show={show}></AlertMessage>
          <VStack space={3} mt="5">
            <Heading fontSize="xl" p="4" pb="3" color="warmGray.600" textAlign="center">
              食材の登録
            </Heading>
            <Input onChangeText={(t) => onChangeText(t)} value={text} />
            <Button
              leftIcon={<Ionicons name="add-circle" size={24} color="white" />}
              onPress={() => {
                onChangeText('');
                setIngredients((oldIngredients) => [...oldIngredients, { id: id, text: text }]);
                setID(id + 1);
              }}
              colorScheme="rose"
              bg="rose.400">
              材料を追加
            </Button>
            <Heading fontSize="xl" p="4" pb="3" color="warmGray.600" textAlign="center">
              食材リスト
            </Heading>
            <FlatList
              data={ingredients}
              renderItem={({ item }) => (
                <Item
                  text={item.text}
                  onPressDelete={() =>
                    setIngredients((oldIngredients) =>
                      oldIngredients.filter((oldIngredient) => oldIngredient.id !== item.id),
                    )
                  }
                />
              )}
              keyExtractor={(item) => String(item.id)}
            />

            <Button
              leftIcon={<Ionicons name="search" size={24} color="white" />}
              onPress={() => callSerchMenue()}
              colorScheme="rose"
              bg="rose.400">
              メニューを検索
            </Button>
          </VStack>
        </Box>
      </Center>
    );
  }
};

export default HomeScreen;
