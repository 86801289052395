import React from 'react';
import {
  Alert,
  VStack,
  HStack,
  Text,
  Box,
  Collapse,
  Center,
  StatusBar,
  Divider,
  Pressable,
  IconButton,
  Icon,
} from 'native-base';
import {
  createDrawerNavigator,
  DrawerNavigationProp,
  DrawerContentScrollView,
} from '@react-navigation/drawer';
import { MaterialIcons } from '@expo/vector-icons';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const AppBar = (props) => {
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('logout');
      })
      .catch((error: any) => {
        console.error(error.messege);
      });
  };

  return (
    <>
      <StatusBar bg="rose.400" barStyle="light-content" />
      <Box safeAreaTop bg="rose.400" />
      <HStack
        bg="rose.400"
        px="1"
        py="3"
        alignItems="center"
        w="100%"
        justifyContent="space-between">
        <Text color="white" fontSize="20" fontWeight="bold">
          RecoMenue
        </Text>
        {props.user ? (
          <IconButton
            icon={<MaterialIcons name="logout" size={24} color="white" onPress={handleLogout} />}
          />
        ) : (
          <></>
        )}
      </HStack>
    </>
  );
};
export default AppBar;
