import React from 'react';
import { Alert, VStack, HStack, Text, Box, Collapse } from 'native-base';

const AlertMessage = (props) => {
  return (
    <Box w="100%" alignItems="center">
      <Collapse isOpen={props.show}>
        <Alert maxW="400" status="error">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}>
                  {props.alertMessage}
                </Text>
              </HStack>
            </HStack>
          </VStack>
        </Alert>
      </Collapse>
    </Box>
  );
};

export default AlertMessage;
