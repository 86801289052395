// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAOeNjjiykcMNttv8LvnvzOW7OywaFWZ8Y',
  authDomain: 'rcomenue.firebaseapp.com',
  projectId: 'rcomenue',
  storageBucket: 'rcomenue.appspot.com',
  messagingSenderId: '883728690984',
  appId: '1:883728690984:web:90f2106a9e72af8c4fea1d',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
