import React, { useState } from 'react';
import { auth } from '../firebase';
import {
  VStack,
  HStack,
  Text,
  Box,
  Center,
  FormControl,
  Heading,
  Input,
  Link,
  Button,
} from 'native-base';

const UnauthorizedEmailScreen = (props) => {
  const returnLogin = async () => {
    // console.log(props.navigation.getId());
    console.log(props);
    props.navigation.goBack();
  };

  return (
    <Center w="100%">
      <Box safeArea p="2" py="8" w="90%" maxW="290">
        <Heading mt="1" color="warmGray.600" fontWeight="medium" size="xs">
          メール認証が完了してません。認証メールを送付しましたので、メールのリンクをクリックして認証を完了させてください
        </Heading>
        <Button onPress={returnLogin} colorScheme="rose" bg="rose.400" mt="5">
          ログイン画面に戻る
        </Button>
      </Box>
    </Center>
  );
};

export default UnauthorizedEmailScreen;
